@import '../../styles/themeVariables.scss';

.feedbackConfirmationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f1f8ff;
  gap: 10rem;

  .header {
    width: 100%;
    background-image: url(../../assets/img/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 2rem 1.5rem;
    display: flex;
    justify-content: flex-start;

    .logo {
      background-image: url('../../assets/img/logo.png');
      background-size: contain;
      background-repeat: no-repeat;
      height: 50px;
      width: 50px;
      margin-right: 1rem;
    }
  }

  .content {
    text-align: center;
    max-width: 600px;
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;

    h1 {
      font-size: 2rem;
      color: $color-primary;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      color: #555;
      margin-bottom: 2rem;
    }

    .backButton {
      background-color: $color-primary;
      color: #fff;
      padding: 0.75rem 1.5rem;
      border-radius: 5px;
      text-decoration: none;
      font-weight: bold;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken($color-primary, 10%);
      }
    }
  }
}
