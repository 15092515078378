@import '../../../../styles/themeVariables.scss';

.templateContainer {
  width: 80%;
  height: fit-content;
  padding: 2rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}

.templateName {
  text-align: center;
  background: linear-gradient(
    90deg,
    rgb(34, 91, 164) 0%,
    rgb(40, 107, 178) 35%,
    rgb(103, 186, 224) 100%
  );
  color: white;
  padding: 20px 70px;
  border-radius: 10px;
  font-size: 1.5rem;
  width: fit-content;
}

.questionsContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background: $color-white;
  padding: 1.5rem;
  border-radius: 10px;
}

.questionsOptionsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.formQuestions {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.hideBtn {
  display: none !important;
}
.showBtn {
  background-color: $color-secondary !important;
  color: $color-white !important;
  width: 5rem !important;
  margin: 0 auto !important;
}

.loadingBtn {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.7 !important;
}
