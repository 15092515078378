#RoadmapDialog {
  padding: 24px;
  max-width: 80vw;
  overflow: auto;
  .RoadmapDialogTitle {
    font: 400 24px/32px;
    letter-spacing: normal;
    margin: 0 0 16px;
  }
  .RoadmapDialogContent {
    display: flex;
    flex-flow: row wrap;
    place-content: center flex-start;
    align-items: center;

    & > * {
      flex-basis: 100%;
    }

    .formRow-50 {
      display: flex;
      justify-content: space-between;
      & > * {
        width: 49.5%;
      }
    }
  }
  .RoadmapDialogActions {
    margin-top: 10px;
    flex: 0 0 auto;
    display: flex;
    padding: 8px 8px 0px 8px;
    align-items: center;
    justify-content: flex-end;

    button {
      text-transform: none;
    }
  }
}
