#EmployeeSkillsRatingHelp {
  display: flex;
  flex-direction: column;
  place-content: stretch flex-start;
  align-items: stretch;
  max-width: 100%;
  font: 400 14px/20px;

  .subHeader {
    height: 40px;
    font-size: 13px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.54);
    padding: 0.5rem 1rem;
  }

  .rankDescription {
    width: 100%;
    font-size: 11px;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
  }
}
