@import '../../../../styles/themeVariables.scss';

.FeedbackRequestWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 0;
  justify-content: space-between;
  .showTemplate {
    background-color: #f1f8ff;
  }
}

.enabledBtn {
  background-color: $color-primary !important;
  color: #fff !important;
  cursor: pointer !important;
}
.disabledBtn {
  background-color: $color-tertiary !important ;
  color: #fff !important;
  cursor: not-allowed !important;
  margin: 0 !important;
}

.feedbackRequestActions {
  margin-top: 10px;
  flex: 0 0 auto;
  display: flex;
  padding: 8px 8px 3px 8px;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  button {
    text-transform: none;
  }
}
