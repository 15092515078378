#ProjectDialog {
  padding: 24px;
  max-width: 80vw;
  overflow: auto;

  .ProjectDialogTitle {
    font: 400 24px/32px;
    letter-spacing: normal;
    margin: 0 0 16px;
  }

  .ProjectDialogContent {
    display: flex;
    flex-flow: row wrap;
    place-content: center flex-start;
    align-items: center;

    & > * {
      flex-basis: 100%;
    }

    .formRow-90 {
      display: flex;
      align-items: flex-end;

      & > :first-child {
        flex: 1 1 90%;
        box-sizing: border-box;
        max-width: 90%;
      }
    }

    .formRow-50 {
      display: flex;
      & > * {
        flex: 1 1 100%;
        max-width: 50%;
      }
    }
  }

  .ProjectDialogActions {
    margin-top: 10px;
    flex: 0 0 auto;
    display: flex;
    padding: 8px 8px 0px 8px;
    align-items: center;
    justify-content: flex-end;

    button {
      text-transform: none;
    }
  }
}
