@import '../../../../styles/themeVariables.scss';

#EmployeeProfile {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  .wrapper {
    max-width: 100%;
    .content {
      display: flex;
      place-content: stretch flex-start;
      align-items: stretch;
      max-height: 100%;
      gap: 1rem;

      .container {
        height: 850px;
        border-radius: 10px;
        box-shadow: $main-box-shadow;
        margin-bottom: 2.5rem;
      }
      .card {
        width: 380px;
        border: none;
        border-radius: 10px;
        padding: 10px;
        background-color: $color-white;
        position: relative;
        height: 100%;
      }

      .upper {
        height: 140px;
        background-color: $color-tertiary;
        border-radius: 10px 10px 0 0;
        display: flex;
        padding: 1rem 0 0 1rem;
        justify-content: space-between;
        align-items: center;
        .welcoming {
          align-self: start;
          font-weight: 700;
          font-size: 18px;
          color: $color-primary;
          text-transform: capitalize;
        }
        img {
          width: 46%;
        }
      }

      .user {
        position: relative;
      }

      .profile .img {
        border-radius: 50%;
        height: 80px;
        width: 80px;
      }

      .profile {
        position: absolute;
        top: -50px;
        left: 5%;
        height: 90px;
        width: 85;
        border: 3px solid $color-white;
        border-radius: 50%;
      }
      .empInfo {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 1rem;
        .name {
          font-weight: 700;
          font-size: 21px;
          color: $color-secondary;
          margin: 2.5rem 0px 0.3rem 0;
          text-transform: capitalize;
          .employeeId {
            font-size: 18px;
            font-weight: 500;
          }
        }
        .employeeWokDetail {
          margin-bottom: 0.3rem;
          .employeeData {
            color: $color-secondary;
            font-size: 14px;
          }
        }
      }
      .employeeDetailsWrapper {
        display: flex;
        flex-direction: column;
        .employeeDetailsList {
          padding-top: 8px;
          font-size: 15px;
          .sectionName {
            font-size: 14px;
            color: $color-secondary;
            font-weight: 600;
            margin-top: 0;
            margin-bottom: 0.5rem;
          }
          .employeeDetail {
            width: 100%;
            font-size: 12px;
            display: flex;
            align-items: center;
            padding: 0.8rem;

            .label {
              width: 40%;
              font-weight: 600;
              color: $color-gray;
              text-transform: capitalize;
            }
            .detail {
              width: 60%;
              padding-left: 16px;
              justify-self: flex-start;
            }
            .detailLink {
              width: 60%;
              padding-left: 16px;
              justify-self: flex-start;
              cursor: pointer;
              color: $color-secondary;
              font-size: 13px;
              font-weight: 600;
            }
          }
        }
      }

      .skillsAndProjects {
        flex: 1 1 100%;
        max-width: 100%;

        .contentWrap {
          gap: 1rem;
          display: flex;
          place-content: flex-start;
          align-items: flex-start;

          .skillsCard {
            width: fit-content;
            flex: 1 1 100%;
            box-sizing: border-box;
            max-width: 100%;
            transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
            display: block;
            position: relative;
            border-radius: 10px;
            margin-bottom: 1em;
            background: $color-white;
            height: 850px;
            box-shadow: $main-box-shadow;
            flex-grow: 1;
            .sectionName {
              font-size: 17px;
              margin: 0;
              padding-left: 1rem;
            }

            .assessmentDate {
              padding-left: 70%;
            }

            .groupCard {
              width: 40%;
              min-width: 300px;
              flex-grow: 1;
              box-shadow: $main-box-shadow;
              border: 0.5px solid #eff2f7;
              height: fit-content;
            }
            .showMoreLessWrapper {
              margin: 1rem 0 0.3rem 0;
              .showMoreLess {
                color: $color-secondary;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                cursor: pointer;
                text-transform: capitalize;
              }
            }
            .cardTitleWithBtn {
              display: flex;
              flex-direction: row;
              box-sizing: border-box;
              place-content: center space-between;
              align-items: center;
              margin-bottom: 8px;
              font-weight: 500;
            }

            .cardBody {
              padding: 1rem 0;
              max-width: 100%;
              width: 100%;
              font-size: 14px;
              height: 620px;
              overflow: auto;
              align-self: center;
              padding-right: 1.5rem;
              .skillCardsWrapper {
                display: flex;
                gap: 0.7rem;
                margin: 0.5rem 0 0 1.5rem;
                flex-wrap: wrap;
              }
            }
          }
        }
      }
    }
  }
  .empty-text-action {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .emptySkills {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    color: $color-primary;
  }
  .empSkill {
    height: auto;
    font-size: 13px;
    background-color: $color-light;
    color: #747880;
    padding: 0.5rem 0.8rem;
    width: 100%;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    align-items: center;
    .skillInfo {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      max-width: calc(45% - 0.25em);
      .skill_name {
        font-weight: 600;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        text-wrap: nowrap;
      }
    }
    .skillDetails {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 52%;
      gap: 0.5rem;
      min-height: 5vh;
      .skillInfoWrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: 0.3rem;
        .info {
          padding-left: 0.3rem;
          text-transform: none;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 100%;
          gap: 0.3rem;
          font-size: 12px;
          .ticIcon {
            color: #14d363;
            font-size: 16px !important;
          }
          .label {
            font-weight: 600;
            padding-right: 0.3rem;
            text-transform: capitalize;
          }
        }
      }
    }
    .MuiButtonBase-root.MuiCheckbox-root {
      padding: 0 !important;
    }
    .skillRating {
      width: 120px;
      justify-self: end;

      .filledStar {
        color: $color-yellow !important;
        display: inline-block;
        fill: currentColor;
      }
    }
  }
}

.groupCardContent {
  padding-bottom: 0.5rem !important;
}
@media all and (max-width: 1024px) {
  .content {
    flex-direction: column;
  }
  .contentWrap {
    flex-direction: column;
  }
  .container {
    height: fit-content !important;
  }
  .cardBody {
    height: fit-content !important;
  }
  .card {
    width: 100% !important;
  }
  .upper img {
    width: 21% !important;
  }
  .skillsCard {
    min-width: 100%;
  }
  .projectsWrapper {
    flex-direction: row !important;
    width: 100% !important;
    min-width: none;
    max-width: none;
    gap: 1.5rem;
  }
  .projects {
    width: 100% !important;
  }
}

@media all and (max-width: 960px) {
  #EmployeeProfile .wrapper .employeeInfo {
    max-width: 100% !important;
  }
}
@media all and (max-width: 768px) {
  .skillInfo {
    max-width: calc(50% - 0.25em) !important;
    .skill_name {
      text-wrap: wrap !important;
    }
  }
  .info {
    font-size: 10px !important;
  }
}

@media all and (max-width: 600px) {
  #EmployeeProfile .wrapper .content .skillsAndProjects .contentWrap .projectsCard {
    flex-direction: column;
    max-height: 100%;
  }
  .content {
    flex-direction: column;
  }
  .contentWrap {
    flex-direction: column;
  }
  .projectsWrapper {
    flex-direction: column !important;
    width: 100% !important;
    min-width: none;
    max-width: none;
    gap: 1.5rem;
  }
  .projects {
    width: 100% !important;
  }
}

@media all and (max-width: 450px) {
  .groupCardContent {
    padding: 10px !important;
  }
  .skillDetails {
    width: 80% !important;
    gap: 0 !important;
    min-height: 8vh !important;
  }
  .info {
    gap: 0 !important;
  }
}

@media all and (max-width: 320px) {
  #EmployeeProfile .wrapper .employeeInfo {
    max-width: 100% !important;
  }
  .card {
    width: 300px !important;
  }
  .skillsAndProjects {
    margin-right: -3rem;
  }
  .skillCardsWrapper {
    margin: 0 !important;
  }
}
