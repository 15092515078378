@import '../../../../../styles/themeVariables.scss';

#RolesList {
  .rolesContainer {
    padding-top: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    .roleContainer {
      height: 48px;
      display: flex;
      align-items: center;
      font: 400 16px/20px;
      max-width: calc(16% - 0.75em);
      min-width: calc(16% - 0.75em);
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: $card-box-shadow;

      .RoleName {
        padding-left: 16px;
        display: flex;
        flex-direction: column;
        flex: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font: 400 16px/28px;
      }
    }
  }
}
