#emptyStateContainer {
  display: flex;
  place-content: center;
  align-items: center;
  flex-direction: column;
  .emptyStateMessage {
    font: 400 15px/24px;
    letter-spacing: normal;
    margin: 0 0 16px;
  }
}
