@import '../../../../../styles/themeVariables.scss';

#titleBarContainer {
  padding-left: 1em;
  margin-bottom: 1em;
  width: 100%;
  min-height: 64px;
  display: flex;
  flex-direction: column;
  font: 500 20px/32px;

  &.primaryTitleBar {
    background: $color-primary;
    color: $color-white;
    text-transform: capitalize;
  }

  &.secondaryTitleBar {
    background: $color-body-white;
    color: $color-dark;
  }

  #secondaryTitleBar {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center flex-start;
    align-items: center;
    padding: 0px 16px;
    width: 100%;
    height: 64px;
  }

  #primaryTitleBar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    padding: 0px 16px;

    .primaryTitleBarTitle {
      font-size: 20px;
      font-weight: 300;
    }
  }
}
