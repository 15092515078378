@import '../../../../styles//themeVariables';

.questionTemplateContainer {
  display: flex;
  flex-direction: column;
  .containerHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    .questionType {
      text-transform: capitalize;
      color: $color-gray;
      font-weight: 600;
    }
  }
  .containerBody {
    margin-bottom: 1rem;
    .radioGroupContainer {
      .radioGroup {
        margin-left: 0;
      }
    }
  }
}
