#RoleDialog {
  padding: 24px;
  overflow: auto;

  .RoleDialogTitle {
    font: 400 24px/32px;
    letter-spacing: normal;
    margin: 0 0 16px;
  }

  .RoleDialogContent {
    width: 100%;
  }

  .RoleDialogActions {
    margin-top: 10px;
    flex: 0 0 auto;
    display: flex;
    padding: 8px 8px 0px 8px;
    align-items: center;
    justify-content: flex-end;

    button {
      text-transform: none;
    }
  }
  .warning {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    margin: 0.5rem 0;
    padding: 0.3rem;
    border-radius: 0.5rem;
    color: #f1b44c;
    background-color: #fbecd2;
  }
}
