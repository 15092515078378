@import '../../../../../styles/themeVariables.scss';

#CurrentInventory {
  position: relative;

  .chart-container {
    min-height: 600px;
    width: 100%;
  }
  #bar-charts {
    display: flex;
    flex-wrap: wrap;
    // place-content: stretch flex-start;
    // align-items: stretch;

    .barChartCard {
      width: calc(332px + 0.75em);
      height: calc(210px + 1em);
      background-color: $color-white;
      font: 400 14px/20px;
      margin-right: 0.75em;
      opacity: 1;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      padding: 16px;
      border-radius: 4px;
      margin-bottom: 1em;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);

      .BarChartCardHeader {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 8px;
      }

      .barChartCardContent {
        width: 300;
        height: 150px;
      }
    }
  }
  #composedChart {
    width: 100%;
    height: 650px;
  }
}
