@import '../../../../../styles/themeVariables.scss';

#EmployeeBasic {
  min-width: 260px;
  width: 20%;
  height: fit-content;
  min-height: 250px;
  padding: 0.5rem;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $card-box-shadow;
  border: 2px solid #d4dbf99e;
  border-radius: 5px;
  .clickableDiv {
    cursor: pointer;
    display: flex;
    place-content: center;
    justify-content: center;
    align-items: center;
    padding: 0.25em;
    width: 100%;

    .empAvatar {
      width: 100px !important;
      height: 100px !important;
      border-radius: 50%;
    }

    .basicEmpInfo {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 1 100%;
      padding: 0.25em;

      .empFullName {
        height: 100%;
        min-height: 100%;
        margin-bottom: 0.5rem;
        max-width: calc(100% - 5.25em) !important;
        padding: 0.25em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .headline {
          width: 100%;
          font-size: 1.3rem;
          font-weight: 600;
          line-height: 1.35417em;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .empJobTitle {
        margin-top: 0.5rem;
        height: 100%;
        min-height: 100%;
        max-width: calc(100% - 5.25em) !important;
        padding: 0.25em;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .subheadline {
          color: #90a4ae !important;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }
    }
  }
}
.lastCard {
  max-width: 24.5%;
}

@media all and (max-width: 1420px) {
  #EmployeeBasic {
    &:last-child {
      max-width: 32%;
    }
  }
}
@media all and (max-width: 770px) {
  #EmployeeBasic {
    &:last-child {
      max-width: 49%;
    }
  }
}
@media all and (max-width: 425px) {
  #EmployeeBasic {
    &:last-child {
      max-width: 100%;
    }
  }
}
