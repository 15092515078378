@import '../../../../styles/themeVariables';
#QuestionDialog {
  padding: 24px;
  overflow: auto;

  .questionTypeSelect {
    width: 100%;
  }

  .QuestionDialogTitle {
    font: 400 24px/32px;
    letter-spacing: normal;
    margin: 0 0 16px;
  }

  .QuestionDialogContent {
    width: 100%;
  }

  .QuestionDialogActions {
    margin-top: 10px;
    flex: 0 0 auto;
    display: flex;
    padding: 8px 8px 0px 8px;
    align-items: center;
    justify-content: flex-end;

    button {
      text-transform: none;
    }
  }
  .warning {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    margin: 0.5rem 0;
    padding: 0.3rem;
    border-radius: 0.5rem;
    color: #f1b44c;
    background-color: #fbecd2;
  }
}

.createOptionContainer {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .optionsPox {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
  }
  .addOption {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2rem;
    width: 100%;
    .addOptionInput {
      width: 90%;
    }
    .btnAddOption {
      border-radius: 5%;
      width: 2rem;
      height: 2rem;
      color: $color-white;
      background: $color-secondary;
      &:hover {
        background: $color-primary;
      }
    }
  }
  .potionsFooter {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    .btnCancelOption {
      color: $color-white;
      background: $color-primary;
      &:hover {
        background: $color-secondary;
      }
    }
    .btnCreateOption {
      color: $color-white;
      background: $color-secondary;
      &:hover {
        background: $color-primary;
      }
    }
  }
}
