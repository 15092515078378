@import '../../../../styles/themeVariables.scss';

.feedbackResponseContainer {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  background-color: #f1f8ff;
  .header {
    width: 100%;
    background-image: url(../../../../assets/img/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 2rem 1.5rem;
    display: flex;
    justify-content: flex-start;

    .logo {
      background-image: url('../../../../assets/img/logo.png');
      background-size: contain;
      background-repeat: no-repeat;
      height: 50px;
      width: 50px;
      margin-right: 1rem;
    }
  }
}

.feedbackSubmittedMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #f9f9f9;
  border-radius: 10px;
  text-align: center;

  h1 {
    font-size: 2rem;
    color: #ff6b6b;
    padding: 20px;
    border: 2px solid #ff6b6b;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
  }
}
