#SkillBasicInfoDialog {
  padding: 24px;
  width: 50em;

  .SkillDialogTitle {
    font: 400 24px/32px;
    letter-spacing: normal;
    margin: 0 0 16px;
  }

  .SkillDialogContent {
    .formGroupHeader {
      margin-top: 20px;
    }

    .BenchmarksWrapper {
      display: flex;
      justify-content: space-between;

      & > * {
        width: 32.6%;
      }
    }
  }

  .SkillDialogActions {
    margin-top: 10px;
    flex: 0 0 auto;
    display: flex;
    padding: 8px 8px 0px 8px;
    align-items: center;
    justify-content: flex-end;

    button {
      text-transform: none;
    }
  }
}
