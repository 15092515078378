@import '../../../styles/themeVariables.scss';

div.sideNav-user-view {
  color: $color-dark;
  font-weight: 600;
  background-image: url(../../../assets/img/bg.jpg);
  background-size: contain;
  padding: 2em 2em 1em;
  margin-bottom: 0.5em;
  text-transform: capitalize;
  div.right {
    margin-top: -3em;
  }
  img.circle {
    height: 4em;
    width: 4em;
  }
  .img {
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  .name {
    font-size: 1.25em;
    line-height: 1.5em;
    height: auto;
    padding: 0;
    font-weight: 500;
    color: $color-white;
  }
  .email {
    height: auto;
    line-height: 1.5em;
    padding: 0;
    font-weight: 400;
    font-size: 0.9em;
  }
}

div.sideNav-listWrapper {
  .sideNav-liWrapper {
    padding-left: 1em;
  }
}

.MuiTypography-displayBlock {
  color: $color-gray;
  font-size: 13px !important;
}
.MuiListSubheader-gutters {
  font-weight: 700 !important;
}
