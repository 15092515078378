.SkillChipList {
  .SkillsList {
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -4px;
    & > * {
      font-weight: 500;
    }
  }

  .SkillsList-dense {
    display: flex;
    place-content: center flex-start;
    align-items: center;
    margin-bottom: -0.5em;
    margin-right: -0.5em;
    flex-wrap: wrap;

    .dense {
      margin-right: 0.5em;
      margin-bottom: 0.5em;
      // width: 1em;
      // height: 1em;
      // border-radius: 50%;
    }
  }
}
