@import '../../../../../styles/themeVariables.scss';

#ProjectList {
  .projectsContainer {
    display: flex;
    flex-wrap: wrap;
    place-content: stretch flex-start;
    align-items: stretch;

    .projectCard {
      background-color: #fff;
      font: 400 14px/20px;
      margin-right: 0.75em;
      opacity: 1;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      padding: 16px;
      border-radius: 4px;
      margin-bottom: 1em;
      box-shadow: $card-box-shadow;

      .projectCardHeader {
        display: flex;
        place-content: center space-between;
        align-items: center;

        .projectCardTitle {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
          font-size: 20px;
        }
      }

      .projectCardContent {
        .progressContainer {
          display: flex;
          flex-direction: column;
          margin-bottom: 0.75em;
          place-content: center flex-start;
          align-items: center;

          .progressPercent {
            margin-bottom: 0.2em;
            font: 500 20px/32px;
          }
        }

        .projectLeader {
          width: 100%;
          display: flex;
          align-items: center;
          place-content: center space-between;
        }
      }
    }
  }
}

@media all and (min-width: 600px) {
  #ProjectList .projectsContainer .projectCard {
    flex-basis: calc(50% - 0.75em);
    max-width: calc(50% - 0.75em);
  }
}

@media all and (min-width: 960px) {
  #ProjectList .projectsContainer .projectCard {
    flex-basis: calc(25% - 0.75em);
    max-width: calc(25% - 0.75em);
  }
}
