@import '../styles/themeVariables.scss';

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
}
.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  background-color: $color-primary;
  color: $color-white;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.Mui-disabled.Mui-disabled {
  color: #dfd9d9 !important;
  cursor: pointer !important;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
  color: $color-secondary;
}
.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
  background-color: $color-primary;
}
