#AllocationDialog {
  padding: 24px;
  max-width: 80vw;
  overflow: auto;

  .ProjectDialogTitle {
    font: 400 24px/32px;
    letter-spacing: normal;
    margin: 0 0 16px;
  }

  .AllocationDialogContent {
    .formRow-90-reversed {
      display: flex;
      align-items: flex-end;

      & > :last-child {
        flex: 1 1 90%;
        max-width: 90%;
      }
    }
    .utilization {
      margin-top: 15px;
      display: flex;
      place-content: center space-between;
      align-items: center;
      max-width: 100%;
      font: 500 20px/32px;
    }
    .effortField {
      display: flex;
      align-items: flex-end;
      & > * {
        flex: 1 1 100%;
        max-width: 50%;
      }
      .effortSlider {
        display: flex;
        margin-bottom: -5px;
      }
    }
    .overlapError {
      color: #f44336;
      text-align: center;
      margin-top: 15px;
    }
  }

  .AllocationDialogActions {
    margin-top: 10px;
    flex: 0 0 auto;
    display: flex;
    padding: 8px 8px 0px 8px;
    align-items: center;
    justify-content: flex-end;

    button {
      text-transform: none;
    }
  }
}
