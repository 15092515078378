@import '../../../../styles/themeVariables.scss';
.feedbackAuthContainer {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.auth-container {
  height: fit-content;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 5px 1px 8px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-field {
  margin-bottom: 20px;
}

.submit-button {
  background-color: $color-secondary;
  &:hover {
    background-color: $color-primary;
  }
}
