@import '../../../../../styles/themeVariables.scss';

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  max-height: 400px !important;
}
.MuiInputBase-input.MuiInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd {
  padding: 0 0 12px 0 !important;
}
.enabledBtn {
  background-color: $color-primary !important;
  color: #fff !important;
  cursor: pointer !important;
}
.disabledBtn {
  background-color: $color-tertiary !important ;
  color: #fff !important;
  cursor: not-allowed !important;
}
.EmployeeSkillsWrapper {
  height: 75vh;
}
.skills {
  max-height: fit-content !important;
  white-space: unset !important;
  font-size: 12px !important;
  min-height: 70px !important;
}
.rows {
  min-height: 70px !important;
}
.clear {
  margin-left: 0.5rem !important;
  cursor: pointer !important;
}
.disabled {
  cursor: not-allowed;
}
.skillInfoWrapper {
  text-transform: capitalize;
  .label {
    font-weight: 600;
  }
  .yes {
    color: #3dcc54;
  }
  .no {
    color: #f03e3e;
  }
  .Expert {
    color: #006400;
  }
  .Proficient {
    color: #008080;
  }
  .Competent {
    color: #808000;
  }
  .Demonstrating {
    color: #ffa500;
  }
  .Basic {
    color: #ff0000;
  }
}
