@import '../../styles/themeVariables.scss';

.login {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;

  .btn-link {
    text-decoration: none;
    font: 500 14px/36px;
    text-transform: capitalize;

    img {
      width: 10em;
    }
  }

  .btn-text-sm {
    font: 400 12px/36px;
    text-transform: capitalize;
  }
  .divider {
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid #0000001c;
  }

  .w-100 {
    width: 100%;
  }

  .login-head {
    flex: 1 1 100%;
    max-height: 100%;
    min-height: 10em;
    margin-bottom: -5em;
    background-image: url(../../assets/img/bg.jpg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(120deg);
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
  }

  .card {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-height: 33%;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    background: $color-white;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    max-width: calc(33%) !important;
    min-width: calc(33%);
    margin-bottom: 1em;
    letter-spacing: normal;

    .cardHeader {
      height: 5em !important;
      display: flex;
      align-items: center;
      background: $color-white;
      color: rgba(0, 0, 0, 0.87);

      .logo {
        background-image: url('../../assets/img/logo.png');
        background-size: contain;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
        flex-shrink: 0;
        object-fit: cover;
      }

      .cardHeaderText {
        white-space: nowrap;
        overflow: hidden;
        margin: 0 16px;
        .cardTitle {
          margin-bottom: 12px;
          font-size: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
          white-space: nowrap;
          padding: 0.3rem 0;
        }
      }
    }

    .cardBody {
      display: block;
      font-size: 14px;

      .wrapper {
        margin-bottom: 1em;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 5em;
          max-width: 100%;
          height: auto;
          border-style: none;
        }
      }

      .cardLink-container {
        height: 100%;
        min-height: 100%;
        min-width: 100%;
        width: 100%;
        flex-direction: column;
        box-sizing: border-box;
        display: flex;
        place-content: center;
        align-items: center;
      }

      .cardActions {
        margin-left: -8px;
        margin-right: -8px;
        padding-bottom: 0.5em !important;
        padding-top: 0.5em !important;
        padding-left: 1em !important;
        padding-right: 1em !important;
        text-align: right;
        &:last-child {
          margin-bottom: -8px;
        }
      }
    }
  }

  form {
    margin-bottom: 1em;

    .formRow {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 1em;

      label {
        width: 100%;
      }
    }
  }

  .formError {
    margin-top: 2em;
    color: #721c24;
    background-color: #f8d7da;
    padding: 0.75rem 1.25rem;
    border: 1px solid #f5c6cb;
    border-radius: 0.25rem;
  }
}

@media all and (max-width: 770px) {
  .card {
    min-width: calc(50%) !important;
    border: 1px solid;
  }
}
@media all and (max-width: 425px) {
  .card {
    min-width: calc(95%) !important;
    border: 1px solid;
  }
}
