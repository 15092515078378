@import '../../../../../styles/themeVariables.scss';

#overallAllocation {
  min-height: 70vh !important;
  .allocationsContainer {
    display: flex;
    flex-wrap: wrap;
    place-content: stretch flex-start;
    align-items: stretch;

    .allocationCard {
      background-color: $color-white;
      font: 400 14px/20px;
      margin-right: 0.75em;
      opacity: 1;
      max-width: calc(15% - 0.75em);
      min-width: calc(15% - 0.75em);
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      padding: 16px;
      border-radius: 4px;
      margin-bottom: 1em;
      box-shadow: $card-box-shadow;

      .allocationCardContent {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        .employeeInfo {
          flex: 1 1 85%;
          max-width: 85%;
        }

        .projectInfo {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          padding: 0.5rem;
          .avgUtilization {
            flex: 1 1 15%;
            max-width: 15%;
            font: 400 24px/32px;
            color: #e91e63;
          }
          .projectsStats {
            margin-left: 0.5rem;
          }
        }
        .employeeCard {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.8rem;
          margin-bottom: 0.5rem;
          .resourceAvatar {
            border-radius: 50%;
            width: 60px !important;
            height: 60px !important;
          }
          .resourceInfo {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .name {
              font-size: 15px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 600px) {
  #overallAllocation .allocationsContainer .allocationCard {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media all and (min-width: 600px) {
  #overallAllocation .allocationsContainer .allocationCard {
    flex-basis: calc(50% - 0.75em);
    max-width: calc(50% - 0.75em);
  }
}

@media all and (min-width: 960px) {
  #overallAllocation .allocationsContainer .allocationCard {
    flex-basis: calc(25% - 0.75em);
    max-width: calc(25% - 0.75em);
  }
}
