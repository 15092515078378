@import '../../../../styles/themeVariables.scss';

#FeedbackDetails {
  width: 80%;
  height: fit-content;
  padding: 0 2rem;
  margin: 0 auto;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 1rem;
  align-items: center;
}

.feedbackResponseDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  background: $color-white;
  padding: 1.5rem;
  border-radius: 10px;
  margin: 0 0 3rem 0;
}

.questionContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.questionDescription {
  font-weight: bold;
  font-size: 1rem;
}

.questionValue {
  margin-left: 1rem;
  font-weight: 600;
  padding: 1rem;
  box-shadow: 3px 5px 0px 0px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 1rem;
  max-width: 70%;
}

.employeeInfo {
  align-items: center;
  background: linear-gradient(
    90deg,
    rgb(34, 91, 164) 0%,
    rgb(40, 107, 178) 35%,
    rgb(103, 186, 224) 100%
  );
  color: white;
  padding: 20px 90px;
  border-radius: 10px;
  font-size: 1.5rem;
  margin-bottom: 20px;
  width: fit-content;
}
