// ------------- COLORS ---------------
$color-primary: #005792;
$color-secondary: #2c76bf;
$color-tertiary: #91c8e4;
$color-light-blue: #baddf4;
$color-light: #baddf42e;
$color-body-white: #f5f5f5;
$color-white: #ffffff;
$color-gray: #495057;
$color-dark: #000000de;
$color-yellow: #ffc400;

//---------------- BOX SHADOW----------
$main-box-shadow: 0 1.5rem 3rem #12263f08;
$card-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
  0px 1px 10px 0px rgba(0, 0, 0, 0.12);
