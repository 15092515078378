#overallAllocationFilter {
  padding: 24px;
  width: 50vw;
  overflow: auto;
  .filterDialogTitle {
    font: 500 20px/32px;
    letter-spacing: normal;
    margin: 0 0 10px;
  }
  .filterDialogContent {
    .formRow-50,
    .allocationRange,
    .skillLevelRange {
      display: flex;
      justify-content: space-between;
      & > * {
        width: 49.5%;
      }
    }
  }
  .filterDialogActions {
    // padding: 8px 8px 0px 8px;
    margin-top: 30px;
    margin-bottom: -10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .spacerDiv {
      flex: 1 1 0%;
    }
    button {
      text-transform: none;
    }
  }
}
