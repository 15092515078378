@import '../../../../../styles/themeVariables.scss';

#tagList {
  width: 100%;
  .tagsContainer {
    width: 100%;
    padding-top: 8px;
    display: flex;
    gap: 1rem;
    .tagContainer {
      height: 56px;
      max-width: calc(16% - 0.75em);
      min-width: calc(16% - 0.75em);
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: $card-box-shadow;
      padding: 0 16px;
      display: flex;
      align-items: center;
      font: 400 16px/20px;

      .tagAvatar {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }

      .tagName {
        padding-left: 16px;
        display: flex;
        flex-direction: column;
        flex: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font: 400 16px/28px;
      }
    }
  }
}
