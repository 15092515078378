.confirmDeleteDialog {
  width: 21.875em;
  padding: 24px 24px 0px 24px;

  .confirmDialogTitle {
    font: 400 24px/32px;
    margin: 0 0 16px;
  }

  .confirmDialogContent {
    max-height: 65vh;
    overflow: auto;

    & > * {
      margin-bottom: 12px;
    }
  }

  .confermDialogActions {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 0;
    flex-wrap: wrap;
    min-height: 52px;

    button {
      text-transform: none;
    }
  }
}
