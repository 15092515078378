#LayoutOverlay2 {
  display: block;

  #main-division {
    position: absolute;
    top: 5.7em;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
  }

  #content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 75vh;
    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    ::-webkit-scrollbar-thumb {
      background-color: darkgray;
    }
    margin: 0 auto;
    main {
      padding: 0px;
      height: 84vh;
      overflow: auto;
      margin-bottom: 1rem;
      align-self: center;
      & > :nth-child(2) {
        min-height: 65vh;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 7rem;
      }

      ::-webkit-scrollbar {
        width: 12px;
        background-color: #969799;
      }

      .fixingDiv {
        font: 500 20px/32px;
        margin-top: calc(64px + 1em); //the height of the titleBar + 1em
      }
    }
  }
  .homePageStyle {
    background: linear-gradient(180deg, #e5e8f2 19%, #ffffff 56%, #c8cfe4);
  }
  .hideTitleBar {
    display: none;
  }
  .hideContent {
    visibility: visible;
  }
  #back-drop {
    display: none;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 3;

    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: background-color, visibility;

    &.active {
      display: block;
      opacity: 1;
    }
  }

  #sidebar {
    min-width: 250px;
    max-width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    /* top layer */
    z-index: 9999;
  }

  #overlay {
    display: none;
    position: fixed;
    /* full screen */
    width: 100vw;
    height: 100vh;
    /* transparent black */
    background: rgba(0, 0, 0, 0.7);
    /* middle layer, i.e. appears below the sidebar */
    z-index: 998;
    opacity: 0;
    /* animate the transition */
    transition: all 0.5s ease-in-out;
  }
  /* display .overlay when it has the .active class */
  #overlay.active {
    display: block;
    opacity: 1;
  }

  #dismiss {
    width: 35px;
    height: 35px;
    position: absolute;
    /* top right corner of the sidebar */
    top: 10px;
    right: 10px;
  }
}

@media all and (max-width: 960px) {
  #content main {
    width: 100% !important;
    & > :nth-child(2) {
      margin-left: 0.5rem !important;
      width: 95% !important;
    }
  }
}
@media all and (max-width: 425px) {
  .hideContent {
    visibility: hidden !important;
  }
}
