.cellHover:hover {
  background-color: #baddf44e !important;
  border: 1px solid black !important;
  cursor: pointer;
}

.disableHover {
  cursor: auto !important;
}

.rightToolbar {
  justify-content: flex-end !important;
}

.avatarStyle {
  height: 31px !important;
  width: 31px !important;
  margin-right: 0.75em !important;
  font-size: inherit !important;
}

.capText {
  text-transform: capitalize !important;
}
