#ClientDialog {
  padding: 24px;
  max-width: 80vw;
  overflow: auto;

  .ClientDialogTitle {
    font: 400 24px/32px;
    letter-spacing: normal;
    margin: 0 0 16px;
  }

  .ClientDialogActions {
    margin-top: 10px;
    flex: 0 0 auto;
    display: flex;
    padding: 8px 8px 0px 8px;
    align-items: center;
    justify-content: flex-end;

    button {
      text-transform: none;
    }
  }
}
