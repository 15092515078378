@import '../../../../../styles/themeVariables.scss';

#AllocatedList {
  .resourcesContainer {
    display: flex;
    flex-wrap: wrap;
    place-content: stretch flex-start;
    align-items: stretch;

    .resourceCard {
      background-color: $color-white;
      font: 400 14px/20px;
      margin-right: 0.75em;
      opacity: 1;
      max-width: calc(16% - 0.75em);
      min-width: calc(16% - 0.75em);
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      padding: 16px;
      border-radius: 4px;
      margin-bottom: 1em;
      box-shadow: $card-box-shadow;

      .resourceCardHeader {
        display: flex;
        place-content: center space-between;
        align-items: center;

        .resourceCardTitle {
          width: calc(100% - 40px);
        }
      }
      .allocationCard {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.8rem;

        .resourceAvatar {
          border-radius: 50%;
          width: 60px !important;
          height: 60px !important;
        }
        .resourceInfo {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          .name {
            font-size: 15px;
            font-weight: 600;
          }
        }
      }
      .resourceCardContent {
        .resourceEffort {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ff4081;
          font: 500 20px/32px Roboto, 'Helvetica Neue', sans-serif;
        }
      }
    }
  }
  .emptyContainer {
    min-height: 75vh;
    width: 100%;
    display: flex;
    place-content: stretch flex-start;
    align-items: stretch;
    .empty-allocation-wrapper {
      flex: 1 1 0%;
      margin-right: 0.75em;
    }
    .verticalDevider {
      width: 1px;
      background-color: rgba(0, 0, 0, 0.12);
      margin-right: 0.75em;
      bottom: 0px;
    }
    .suggested-wrapper {
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
      place-content: stretch flex-start;
      align-items: stretch;
      max-width: 33%;

      .suggested-title {
        color: rgba(0, 0, 0, 0.87);
        font: 400 16px/28px Roboto, 'Helvetica Neue', sans-serif;
        margin: 0 0 16px;
        letter-spacing: normal;
      }
      .suggestedCardsContainer {
        display: flex;
        flex-direction: column;
        place-content: stretch flex-start;
        align-items: stretch;
        max-width: 100%;

        .suggested-card {
          margin-bottom: 0.5em;
          transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
          // position: relative;
          padding: 16px;
          border-radius: 4px;
          background: $color-white;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);

          .suggested-card-content {
            flex-flow: row wrap;
            display: flex;
            place-content: flex-start space-between;
            align-items: flex-start;
            .employeeInfo {
              flex: 1 1 85%;
              max-width: 85%;
            }
            .allocationAmount {
              flex: 1 1 15%;
              max-width: 15%;
              font: 400 24px/32px Roboto, 'Helvetica Neue', sans-serif;
              margin: 0 0 16px;
              color: #e91e63;
            }
            .suggestedSkills {
              display: flex;
              place-content: center flex-start;
              align-items: center;
              flex: 1 1 100%;
              max-width: 100%;
              color: #ff4081;

              & > * > * {
                margin-right: 0.5em;
              }

              img {
                width: 2em;
                height: 2em;
                border-radius: 50%;
              }
            }
            .suggestedCardAction {
              display: flex;
              place-content: center;
              align-items: center;
              flex: 1 1 100%;
              max-width: 100%;

              .projectsStats {
                font-size: 12px;
              }
              .spacerDiv {
                flex: 1 1 0%;
              }
              button {
                border-color: rgba(0, 0, 0, 0.12) !important;
                text-transform: unset;
                &:hover {
                  border-color: rgba(0, 0, 0, 0.12);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 600px) {
  #AllocatedList .resourcesContainer .resourceCard {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media all and (min-width: 600px) {
  #AllocatedList .resourcesContainer .resourceCard {
    flex-basis: calc(50% - 0.75em);
    max-width: calc(50% - 0.75em);
  }
}

@media all and (min-width: 960px) {
  #AllocatedList .resourcesContainer .resourceCard {
    flex-basis: calc(25% - 0.75em);
    max-width: calc(25% - 0.75em);
  }
}
