#SkillList {
  .skillsContainer {
    .skillGroup {
      padding-top: 8px;
      .groupName {
        height: 48px;
        color: rgba(0, 0, 0, 0.54);
        display: flex;
        padding: 16px;
        align-items: center;
        margin-top: -8px;
        font: 500 14px/16px;
      }

      .skillContainer {
        height: 56px;
        width: 100%;
        padding: 0 16px;
        display: flex;
        align-items: center;
        font: 400 16px/20px;

        .skillImg {
          flex-shrink: 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }

        .skillName {
          padding-left: 16px;
          display: flex;
          flex-direction: column;
          flex: auto;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font: 400 16px/28px;
        }
      }
    }
  }
}
