@import '../../../../styles/themeVariables.scss';

.viewControlWrapper {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-bottom: 0.3rem;
  .viewOptions {
    display: flex;
    gap: 0.5rem;
    .option {
      cursor: pointer;
      color: $color-dark !important;
    }
    .active {
      border: 2px solid $color_primary;
      border-radius: 0.3rem;
      color: $color_primary !important ;
    }
  }
}
.btnLink {
  background: $color-secondary !important;
  text-transform: capitalize !important;
  color: $color-white !important;
}

#FeedbackCard {
  min-width: 260px;
  width: 20%;
  height: fit-content;
  min-height: 250px;
  padding: 0.5rem;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $card-box-shadow;
  border: 2px solid #d4dbf99e;
  border-radius: 5px;
  .clickableCard {
    cursor: pointer;
    display: flex;
    place-content: center;
    justify-content: center;
    align-items: center;
    padding: 0.25em;
    width: 100%;

    .avatar {
      width: 100px !important;
      height: 100px !important;
      border-radius: 50%;
    }

    .empInfo {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 1 100%;
      padding: 0.25em;

      .fullName {
        height: 100%;
        min-height: 100%;
        margin-bottom: 0.5rem;
        max-width: calc(100% - 5.25em) !important;
        padding: 0.25em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .headline {
          width: 100%;
          font-size: 1.3rem;
          font-weight: 600;
          line-height: 1.35417em;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .clientInfo {
        margin-top: 0.5rem;
        height: 100%;
        min-height: 100%;
        max-width: calc(100% - 5.25em) !important;
        padding: 0.25em;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .cLientName,
        .clientEmail {
          color: #90a4ae !important;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }

      .statusInfo {
        text-align: center;
        font-weight: 600;
        text-transform: capitalize;
        color: #fff;
        margin-top: 0.7rem;
        .statusPending {
          background: #f8b400;
          padding: 0.3rem 0.8rem;
          border-radius: 1rem;
        }
        .statusCompleted {
          background: #388e3c;
          padding: 0.3rem 0.8rem;
          border-radius: 1rem;
        }
      }
    }
  }

  .disableCard {
    cursor: not-allowed;
  }
}
.lastCard {
  max-width: 24.5%;
}

@media all and (max-width: 1420px) {
  #EmployeeBasic {
    &:last-child {
      max-width: 32%;
    }
  }
}
@media all and (max-width: 770px) {
  #EmployeeBasic {
    &:last-child {
      max-width: 49%;
    }
  }
}
@media all and (max-width: 425px) {
  #EmployeeBasic {
    &:last-child {
      max-width: 100%;
    }
  }
}
