@import '../../../../../styles//themeVariables.scss';

.questionLabel {
  font-size: 1rem;
  color: $color-white;
  display: block;
  background: linear-gradient(
    90deg,
    rgb(34, 91, 164) 0%,
    rgb(40, 107, 178) 35%,
    rgb(103, 186, 224) 100%
  );
  padding: 15px 10px;
  border-radius: 10px;
}

.radioGroup {
  margin-left: 0.5rem;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}
.noResize {
  resize: none;
}
