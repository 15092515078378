@import '../../../styles/themeVariables.scss';

#TopNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: $color-primary;
  color: $color-white;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  min-height: 64px;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);

  .topNavRow {
    display: flex;
    box-sizing: border-box;
    padding: 0 16px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    height: 64px;
    color: $color-white;

    .spacer {
      flex: 1 1 auto;
    }

    #title {
      display: inline-block;
      padding-left: 10px;
      font-size: 20px;
      font-weight: 500;
    }
  }
}
