@import '../../../../styles/themeVariables.scss';

#QuestionList {
  display: flex;
  justify-content: center;
  width: 100% !important;

  .questionsContainerList {
    padding-top: 8px;
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    .questionContainerList {
      min-height: 10rem;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      padding: 1rem;
      gap: 1rem;
      margin-bottom: 1.5rem;
      font: 400 16px/20px;
      max-width: calc(90% - 0.75em);
      min-width: calc(16% - 0.75em);
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: $card-box-shadow;
      width: 100%;

      .questionListHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        .questionNumber {
          font-weight: 600;
        }
      }

      .questionInformation {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-left: 1rem;
        .questionTextType {
          width: 100%;
          .textareaText {
            width: 60% !important;
            height: 3rem !important;
          }
        }

        .questionRatingType {
          width: 100%;
          display: flex;
          .questionRadioGroup {
            margin-left: 0;
            display: flex;
            flex-direction: row;
            gap: 3rem;
          }
        }
      }
    }
  }
}
