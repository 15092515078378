* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: currentColor;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
}

input {
  border: none;
}

p {
  margin: 0;
}
