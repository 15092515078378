@import '../../../styles/themeVariables.scss';
#BenefitsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 60vh !important;
  margin-top: 10rem;
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .slider {
      width: 100%;
      height: 100%;
      padding-top: 5%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .slides {
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: $color-primary;
        width: 100%;
        .benefitCard {
          font-size: 24px;
          font-weight: 600;
          line-height: 1.5;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 80%;
          color: $color-primary;
        }
      }
      .buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;
        align-self: flex-end;
        .benefit {
          max-width: calc(15% - 0.75em);
          min-width: calc(15% - 0.75em);
          filter: grayscale(1);
          font-size: 18px;
          color: $color-primary;
          box-sizing: border-box;
          border-bottom: 3px solid transparent;
          &:hover {
            filter: grayscale(0);
            font-weight: 600;
            border-bottom: 3px solid $color-primary;
          }
          &.notActive {
            filter: grayscale(1);
          }
          &.active {
            filter: grayscale(0);
            font-weight: 600;
            border-bottom: 3px solid $color-primary;
          }
        }
      }
    }
  }
}
@media all and (max-width: 1024px) {
  .slider {
    .benefitCard {
      font-size: 19px !important;
    }
    .benefit {
      font-size: 14px !important;
      max-width: calc(17% - 0.75em);
      min-width: calc(17% - 0.75em);
      img {
        width: 80px !important;
      }
    }
  }
}
@media all and (max-width: 770px) {
  .slider {
    padding-bottom: 2rem;
    .benefitCard {
      font-size: 18px !important;
    }
  }
}
@media all and (max-width: 425px) {
  .slider {
    justify-content: center !important;
    flex-direction: column-reverse !important;
    padding-top: 0% !important;
    .benefit {
      display: none;
      margin: 0 2rem;
      font-size: 18px !important;
      border-bottom: none !important;
      img {
        width: 120px !important;
      }
      &.active {
        max-width: calc(90% - 0.75em) !important;
        min-width: calc(90% - 0.75em) !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .benefitCard {
      font-size: 16px !important;
      color: $color-dark !important;
      width: 65% !important;
    }
  }
}
@media all and (max-width: 320px) {
  .slider {
    .benefit {
      display: none;
      margin: 0 !important;
      font-size: 16px !important;
      border-bottom: none !important;
      img {
        width: 90px !important;
      }
      &.active {
        max-width: calc(100% - 0.75em) !important;
        min-width: calc(100% - 0.75em) !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .benefitCard {
      font-size: 16px !important;
      color: $color-dark !important;
      width: 70% !important;
      align-self: flex-end !important;
    }
  }
}
