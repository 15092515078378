@import '../../../styles/themeVariables.scss';

.footer {
  display: flex;
  place-content: center space-between;
  align-items: center;
  padding: 0 16px;
  background: $color-primary;
  color: $color-white;
  width: 100%;
  min-height: 25px;
  position: fixed;
  bottom: 0;
  left: 0;

  .rights {
    width: 33%;
  }
  .version {
    font-size: 12px;
  }
  .Aspirewebsite {
    width: 33%;
    text-align: right;
    text-decoration: none;
    color: $color-white !important;
  }
}
