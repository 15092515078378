.certificateDialog {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 500px;
  padding: 1rem;
  padding-top: 0;
  .actionsWrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 1rem;
  }
  .fieldWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

}
.MuiInputBase-input {
  height: auto !important;
}
