.file-input {
  color: transparent;
}

.upload-form {
  display: flex;
  flex-direction: column;
  border: 2px #1475cf;
  max-height: 300px;
  max-width: 300px;
  border-radius: 5px;
  margin-bottom: 1%;
}

input[type='file'] {
  outline: none;
  padding: 4px;
  margin: -4px;
}

input[type='file']:focus-within::file-selector-button,
input[type='file']:focus::file-selector-button {
  outline: 2px solid #0964b0;
  outline-offset: 2px;
}

input[type='file']::before {
  top: 16px;
}

input[type='file']::after {
  top: 14px;
}

/* ------- From Step 2 ------- */

input[type='file'] {
  position: relative;
}

input[type='file']::file-selector-button {
  width: 90px;
  color: transparent;
}

/* Faked label styles and icon */
input[type='file']::before {
  position: absolute;
  pointer-events: none;
  /*   top: 11px; */
  left: 40px;
  color: #0964b0;
  content: 'Import';
}

input[type='file']::after {
  position: absolute;
  pointer-events: none;
  /*   top: 10px; */
  left: 16px;
  height: 20px;
  width: 20px;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
}

/* ------- From Step 1 ------- */

/* file upload button */
input[type='file']::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
}

/* file upload button hover state */
input[type='file']::file-selector-button:hover {
  background-color: #f3f4f6;
}

/* file upload button active state */
input[type='file']::file-selector-button:active {
  background-color: #e5e7eb;
}
@media all and (max-width: 320px) {
  .upload-form {
    width: 245px;
  }
}
