.TagsChipList {
  .tagsViewMode {
    .tagsList {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: -4px;
      & > * {
        font-weight: 500;
      }
    }

    .tagsList-dense {
      display: flex;
      place-content: stretch flex-start;
      align-items: stretch;
      margin-bottom: -0.5em;
      margin-right: -0.5em;
      flex-wrap: wrap;

      .dense {
        margin-right: 0.5em;
        margin-bottom: 0.5em;
        width: 1em;
        height: 1em;
        border-radius: 50%;
      }
    }
  }
}
