@import '../../../../styles//themeVariables.scss';

#TemplateInfoContainer {
  margin: 3rem 0;
}

.btnContainer {
  display: flex;
  justify-content: center;
  .closeBtn {
    background: $color-primary;
    color: $color-white;
    &:hover {
      background: $color-secondary;
    }
  }
}
