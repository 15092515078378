@import '../../../../../styles/themeVariables.scss';

#ClientsList {
  .clientsContainer {
    display: flex;
    flex-wrap: wrap;
    place-content: stretch flex-start;
    align-items: stretch;
    .clientCard {
      font: 400 14px/20px;
      margin-right: 0.75em;
      opacity: 1;
      max-width: calc(16% - 0.75em);
      min-width: calc(16% - 0.75em);
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      padding: 16px;
      border-radius: 4px;
      margin-bottom: 1em;
      box-shadow: $card-box-shadow;

      .clientCardHeader {
        display: flex;
        place-content: center space-between;
        align-items: center;

        .clientCardTitle {
          white-space: nowrap;
          overflow: hidden;
          margin: 0 16px;
          text-overflow: ellipsis;
          font-weight: 500;
          font-size: 20px;
        }
      }

      .clientCardImage {
        width: calc(100% + 32px);
        margin: 0 -16px 16px -16px;
      }
    }
  }
}
