@import '../../../../styles/themeVariables.scss';

#EmployeeSkills {
  width: 90%;
  margin: 0 auto;
  min-height: 75vh;
  .allEmpSkills {
    width: 100%;
    .employeeSkillWrapper {
      width: 100%;
      padding: 0 24px 16px;
      display: flex;
      place-content: center space-between;
      align-items: center;
      border-bottom: 1px solid #e8eaed;
      .skillImg {
        width: 3em;
        height: 3em;
        flex: 0 0 auto;
        margin-right: 0.5em;
        border-radius: 50%;
      }

      .skillName {
        margin-right: 0.5em;
        flex: 1 1 100%;
        max-width: 15%;
        padding-bottom: 1.5%;
      }

      .rating {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        flex: 1 1 100%;
        padding: 0.35em 0.75em 0.625em;
        font: 400 14px/20px;
        > {
          input {
            display: none;
          }
          label {
            &:before {
              margin: 0px 4px;
              font-size: 2vw;
              display: inline-block;
            }
            color: #bdbdbd;
            float: right;
            cursor: pointer;
          }
          input:checked ~ label {
            color: $color-yellow;
          }
        }
      }

      label:hover {
        ~ {
          input:checked ~ label,
          label {
            color: $color-yellow;
          }
        }
        color: $color-yellow;
      }

      .rating > input {
        + label:hover {
          color: $color-yellow;
        }
        &:checked {
          &:hover + label,
          ~ label:hover ~ label {
            color: $color-yellow;
          }
        }
      }

      .checkBox {
        padding: 7px 4px 7px 1px;
        border: 1px solid #e8eaed;
        border-radius: 5px;
        background: $color-white;
        box-shadow: 0 1px 3px -2px #9098a9;
        cursor: pointer;
        font-family: inherit;
        font-size: 12px;
        margin-left: 5%;
        flex: 1 1 30%;
        color: #5a667f;
        min-width: 30% !important;
      }

      .skillRatingSummary {
        flex: 2 2 25%;
        text-align: right;
        font-weight: 600;
      }
    }
  }

  .dividerDiv {
    margin: 1em 0;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgba(0, 0, 0, 0.12);
  }
  .pageActions {
    display: flex;
    place-content: center flex-end;
    align-items: center;
    margin-top: 1rem;
    box-shadow: $main-box-shadow;
    padding: 10px;
    background-color: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    position: sticky;
    bottom: 0;
  }

  .MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.formControlLabel {
  min-width: 30% !important;
}
.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-size: 14px !important;
}
@media all and (max-width: 1024px) {
  .skillName {
    max-width: 25% !important;
  }
  .employeeSkillWrapper {
    padding: 0 0.5rem !important;
  }
  .rating {
    flex-direction: column;
    align-items: flex-start !important;
    margin-left: 1.5rem !important;
  }
  .checkBox {
    margin-left: 0 !important;
  }
}
@media all and (max-width: 320px) {
  .skillName {
    max-width: 20% !important;
  }
  .rating {
    margin-left: 1rem !important;
  }
  .checkBox {
    max-width: 180px !important;
  }
  .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-size: 12px !important;
  }
}
