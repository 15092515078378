@import '../../../../../styles/themeVariables.scss';

#employeesWithMissingSkills {
  .employeesWrapper {
    display: flex;
    flex-wrap: wrap;
    place-content: stretch flex-start;
    align-items: stretch;

    .empCard {
      background-color: white;
      font: 400 14px/20px;
      margin-right: 0.75em;
      opacity: 1;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      padding: 16px;
      border-radius: 4px;
      margin-bottom: 1em;
      max-width: calc(16% - 0.75em) !important;
      min-width: calc(16% - 0.75em) !important;
      box-shadow: $card-box-shadow;

      .employeeCardHeader {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8px;
      }
      .employeeCircledImage {
        padding: 1em;
        width: calc(100% + 32px);
        margin: 0 -16px 16px -16px;
        border-radius: 50%;
      }
      .employeeCardCotent {
        display: flex;
        flex-direction: column;
        place-content: center;
        align-items: center;
        .employeeJobTitle {
          max-width: 100%;
          margin-bottom: 0.75em;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font: 400 14px/20px;
          letter-spacing: normal;
        }
        .employeeDepartment {
          max-width: 100%;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font: 400 12px/20px;
          letter-spacing: normal;
        }
      }
    }
  }
}

@media all and (min-width: 600px) {
  #employeesWithMissingSkills .employeesWrapper .empCard {
    flex-basis: calc(50% - 0.75em);
    max-width: calc(50% - 0.75em);
  }
}

@media all and (min-width: 960px) {
  #employeesWithMissingSkills .employeesWrapper .empCard {
    flex-basis: calc(25% - 0.75em);
    max-width: calc(25% - 0.75em);
  }
}

.rightToolbar {
  justify-content: flex-end !important;
}
