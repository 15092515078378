@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Roboto:wght@400;500;600;700;900&display=swap');
.btn-circle {
  text-align: center;
  border: none;
  outline: none;
  color: inherit;
  background-color: transparent;
  //   default if not mentioned the size
  width: 50px;
  height: 50px;
  padding: 7px 10px;
  border-radius: 25px;

  &.btn-sm {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
  }
  &.btn-md {
    width: 50px;
    height: 50px;
    padding: 7px 10px;
    border-radius: 25px;
  }
  &.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
  }
}
