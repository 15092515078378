.auditViewer {
  padding-top: 8px;

  .audit {
    height: 72px;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    .auditWrapper {
      .auditUser {
        font: 400 16px/28px;
      }

      .auditDetails {
        font: 400 14px/20px;
      }
    }
  }
}
