#accessDenied {
  display: flex;
  flex-direction: column;
  align-items: center;
  .accessDeniedMessage {
    font: 400 24px/32px;
    letter-spacing: normal;
    margin: 0 0 16px;
  }
}
