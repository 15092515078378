@import '../../../../styles/themeVariables.scss';

.projectsWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 320px;
  max-width: 320px;
  max-height: 92.5vh;
  background-color: $color-white;
  padding: 0.5rem;
  border-radius: 10px;

  .sectionName {
    font-size: 16.25px;
    margin: 0;
    color: #2c76bf;
  }
  .projects {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .projectCard {
      box-shadow: $main-box-shadow;
      border: 0.5px solid #eff2f7;
      .projectDataWrapper {
        display: flex;
        justify-content: space-between;
        font-size: 11.3px;
        padding: 0.5rem 0 0.5rem 0.5rem;
        .projectData {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .label {
            font-weight: 600;
            color: $color-gray;
            margin-right: 0.5rem;
          }
          .dataLink {
            cursor: pointer;
          }
        }
      }
      .team {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .teamMembers {
          align-self: flex-start;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 2px;
        }
      }
    }
  }

  .teamMemberImg {
    width: 50px !important;
    height: 50px !important;
    font-size: 17px;
    border-radius: 50%;
  }
  .employees {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    max-height: 100%;
    min-height: 50%;
    background-color: $color-white;

    .empCardsWrapper {
      align-self: center;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0 0.3rem;

      .empCard {
        cursor: pointer;
        min-height: 65px;
        box-shadow: none !important;
        border: 0.5px solid #eff2f7;
        .empCardBody {
          margin: 0.5rem;
          display: flex;
          flex-direction: row;
          padding: 0;
          .empData {
            margin-left: 0.5rem;
            display: flex;
            flex-direction: column;
          }
          .name {
            font-size: 13px;
            font-weight: 600;
          }
          .email {
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .employeeCertificates {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    min-height: 25.5vh;
    max-height: 25.5vh;

    .titleWithBtn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-right: 0.5rem;
      margin-bottom: 0.5rem;
      & > :nth-child(2) {
        cursor: pointer;
      }
    }
    .certificatesWrapper {
      margin-top: 1rem;
      overflow-y: auto;
      overflow-x: hidden;
      .certificates {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
        .certificate {
          display: flex;
          gap: 0.3rem;
          width: 100%;
          justify-content: space-between;
          border: 1px solid #eee;
          background-color: $color-white;
          padding: 0.3rem;
          box-shadow: $main-box-shadow;
          border-radius: 5px;
          .certificateInfo {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            max-width: calc(80% - 0.25em);
            .nameAndIcon {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              cursor: pointer;
              width: 100%;
              .certificateName {
                text-transform: capitalize;
                font-size: 16px;
                font-weight: 600;
                text-overflow: ellipsis;
                max-width: 90%;
              }
              .certificateURL {
                color: $color-secondary;
                cursor: pointer;
                & > :nth-child(1) {
                  font-size: 14px !important;
                }
              }
            }
            .certificateDate {
              font-size: 13px;
            }
          }
          .certificateActions {
            display: flex;
            width: 18%;
            justify-content: space-between;
            align-items: center;
            & > :nth-child(1) {
              color: #7a7979 !important;
              cursor: pointer;
            }
            & > :nth-child(2) {
              color: #d63737 !important;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
@media all and (max-width: 600px) {
  .projectsWrapper {
    min-width: 100%;
    padding-right: 1rem;
  }
}
