@import '../../../../styles/themeVariables.scss';

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-row:hover {
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 4px !important;
}
::-webkit-scrollbar-track {
  background-color: #eef0fcb6 !important;
}

::-webkit-scrollbar-thumb {
  background-color: #91c8e4 !important;
}

.controlWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.3rem;
  .viewOptions {
    display: flex;
    gap: 0.5rem;
    .option {
      cursor: pointer;
      color: $color-dark !important;
    }
    .active {
      border: 2px solid $color_primary;
      border-radius: 0.3rem;
      color: $color_primary !important ;
    }
  }
}
.gridWrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.employeeGridWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}
.pagination {
  width: 100%;
  margin-top: 1rem;
}

.MuiPagination-ul {
  justify-content: center !important;
}
