@import '../../../../../styles/themeVariables.scss';

#initatives {
  .initiativesContainer {
    display: flex;
    flex-wrap: wrap;
    place-content: stretch flex-start;
    align-items: stretch;
    .initCard {
      display: block;
      text-align: left;
      background-color: $color-white;
      font: 400 14px/20px;
      margin-right: 0.75em;
      opacity: 1;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      padding: 16px;
      border-radius: 4px;
      margin-bottom: 1em;
      box-shadow: $card-box-shadow;

      .initCardHeader {
        .initCardTitle {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
          font-size: 20px;
          margin: 0 16px;
          margin-bottom: 12px;
        }
      }
      .initCardContent {
        display: flex;
        flex-flow: row wrap;
        place-content: center flex-start;
        align-items: center;
        margin: 0px -0.25em -0.25em 0px;
        cursor: pointer;
      }
    }
  }
}

@media all and (min-width: 600px) {
  #initatives .initiativesContainer .initCard {
    flex-basis: calc(50% - 0.75em);
    max-width: calc(50% - 0.75em);
  }
}

@media all and (min-width: 960px) {
  #initatives .initiativesContainer .initCard {
    flex-basis: calc(25% - 0.75em);
    max-width: calc(25% - 0.75em);
  }
}
