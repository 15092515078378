@import '../../../../styles//themeVariables.scss';

#QuestionInfoContainer {
  margin: 1.5rem;
  min-width: 400px;
}

.btnContainer {
  display: flex;
  justify-content: center;
  .closeBtn {
    background: $color-primary;
    color: $color-white;
    &:hover {
      background: $color-secondary;
    }
  }
}
